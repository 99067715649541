// 
// card.scss
//

.card {
    margin-bottom: $grid-gutter-width;
    .card-drop {
        font-size: 24px;
        line-height: 0;
        color: $gray-600;
    }
}

// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}

.card-title{
    font-size: 15px;
}

.header-title {
    font-size: 1.1rem;
    margin: 0 0 7px 0;
}

.sub-header {
    margin-bottom: $grid-gutter-width;
    color: $gray-500;
    font-size: 14px;
}

// card header

.card-header{
    border-bottom: none;
}

// card footer

.card-footer{
    border-top: 1px solid $gray-200;
}

// card border

.card-border{
    .card-header{
        border-top: 2px solid $gray-400;
    }
}

// Custom card box
.card-box {
    background-color: $card-bg;
    padding: $card-spacer-x;
    margin-bottom: $grid-gutter-width;
    border: $card-border-width solid $card-border-color;
    @include border-radius($card-border-radius);
}

.card-widgets {
    float: right;
    height: 16px;
    > a {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;

        &.collapsed {
            i {
                &:before {
                    content: "\F415";
                }
            }
        }
    }
}


//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    cursor: progress;
    .card-portlets-loader {
        background-color: $dark;
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: - ($grid-gutter-width / 2);
        margin-top: - ($grid-gutter-width / 2);
    }
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
